import React from 'react'
import  { FaBars } from 'react-icons/fa'
import { Nav , NavbarContainer , NavLogo , MobileIcon , NavMenu , 
         NavItem , NavLinks , BtnSwLang } from './NavbarElements';
import './Navbar.css'
import Socials from '../Socials';

import { useCookies } from 'react-cookie';

const Navbar = ({toggle}) => {
    
  const [cookies, setCookie] = useCookies(['lang']);


    let changeLang = () =>{
        if (cookies.lang==='md'){setCookie('lang','ru',{ path: '/' })} else {setCookie('lang','md',{ path: '/' })}
        
    }
  
    return (
        <div>
            <Nav>
                <NavbarContainer>
                    <NavLogo to="/">
                        ENVY
                    </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                    
                        <NavItem>
                            <NavLinks to="/price">{cookies.lang!=='md' ? 'Цены' : 'Price'}</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/work">{cookies.lang!=='md' ? 'Наши работы' : 'Our Work'}</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/vacancy">{cookies.lang!=='md' ? 'Вакансии' : 'Vacancy'}</NavLinks>
                        </NavItem>
                        
                        
                    
                    </NavMenu>
                    <BtnSwLang  onClick={changeLang}>{cookies.lang==='md' ? 'RU' : 'MD'}</BtnSwLang>
                    <Socials />
                </NavbarContainer>
            </Nav>
        </div>
    )
}

export default Navbar;
