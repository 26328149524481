import styled from "styled-components";

export const InfoBlock = styled.div`
  width: 100%;
  height: auto;
  /* background:linear-gradient(45deg, #000000, #dcdcdc); */
  background: #f6f6f6;
  background-size: cover;
`;

export const InfoContainer = styled.div`
  width: 100%;
  padding-top: 25px;
`;

export const InfoHeader = styled.h2`
  font-size: 3rem;
  margin-bottom: 25px;
  text-align: center;
  color: #b2904f;

  /* 
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #c7c7c7; */

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;

export const Informarion = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const InfoItems = styled.div`
  width: calc(33% - 40px);
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
  height: 400px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 95%;
    margin: 10px;
  }
`;

export const InfoFrame1 = styled.div`
  width: calc(50% - 3px);
  margin: 1.5px;
  height: 50%;
  display: flex;
  position: relative;
  border-radius: 2px;
  border-top-left-radius: 50px;
  overflow: hidden;

  box-shadow: #b2b2b2 0px 0px 10px 5px;
`;

export const InfoFrame2 = styled.div`
  width: calc(50% - 3px);
  margin: 1.5px;
  height: 50%;
  border-radius: 2px;
  border-bottom-right-radius: 50px;
  display: flex;
  position: relative;
  overflow: hidden;

  box-shadow: #b2b2b2 0px 0px 10px 5px;
`;

export const InfoFrame3 = styled.div`
  width: 100%;
  margin-top: 3px;
  margin-bottom: 3px;
  height: 50%;
  border-radius: 2px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  display: flex;
  position: relative;
  overflow: hidden;

  box-shadow: #b2b2b2 0px 0px 10px 5px;
`;

export const InfoFrame4 = styled.div`
  width: 100%;

  height: 100%;
  border-radius: 2px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  display: flex;
  position: relative;
  overflow: hidden;

  box-shadow: #b2b2b2 0px 0px 10px 5px;
`;

export const ImageBox = styled.img`
  width: auto;
  max-width: 100%;
  /* height: 50vh; */
  padding: 10px;
  border-radius: 5px;
`;
export const ImageItem = styled.div`
  display: flex;
  justify-content: center;
`;
export const InfoImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.4s ease;

  &:hover {
    transform: scale(1.3);
  }
`;

export const InfoSubtitle = styled.p`
  color: #b2904f;
  font-weight: 700;
  position: absolute;
  font-size: 24px;
  bottom: 10px;
  left: 15px;
  z-index: 1;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #c3c3c3;
  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`;
