import styled from "styled-components";

export const Portfolio = styled.div`
  width: 100%;
  background: #f6f6f6;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const PortfolioContainer = styled.div`
  width: 100%;
  padding-top: 30px;
`;

export const PortfolioHead = styled.h2`
  text-align: center;
  width: 100%;
  color: #b2904f;
  font-size: 48px;
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    font-size: 36px;
    margin-bottom: 30px;
  }
`;

export const PortfolioImages = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 30px;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 35px;

  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`;
export const PortfolioImage = styled.div`
  padding: 2px;
  width: 25%;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    width: 45%;
  }

  &:hover {
    transform: scale(1.2);
    transition: 0.6s ease;
    z-index: 10;
  }
`;
export const PortfolioImg = styled.img`
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
`;
