import { Button, InputAdornment, TextField } from '@material-ui/core';
import React from 'react'
import { UserItem, UserShield } from './AdminEllements'
import {  makeStyles } from '@material-ui/core/styles';

import DeleteUser from './DeleteUser';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: '10px 10px 10px 0',
        width: '100%',

        color:'black',
        '& Mui-focused':{
          color:'black'
        },
        '& label': {
          color:'Black',
          '&.Mui-focused':{
            color:'black'
          }
        },
        '& fieldset':{
          borderColor:'grey',
          '&:hover , &:focus':{
            borderColor:"grey"
          }
         },
         
      '& textarea':{
        color:'black',
        '&:hover , &:focus':{
          borderColor:"grey"
        }
      },
      '& MuiTextField-root':{
        width:'45%',
      },

        '&.MuiButton-outlined':{
          borderColor:'black',
        }
      
       
      },
      
    },
    
  }));



const Users = () => {
  const { enqueueSnackbar } = useSnackbar();

    const classes = useStyles();

    const [userses, setUserses] = React.useState({
        newUser:'',
        newPsw:''  
      });

    const handleChange = (prop) => (event) => {
        setUserses({ ...userses, [prop]: event.target.value });
    }; 

    const resetUserForm = () =>{
        setUserses({
            newUser:'',
            newPsw:''  
        })
    }

    const addUser = (e) => {
        console.log(userses)
        e.preventDefault();
    
      let data= {
        user: userses.newUser,
        pasw: userses.newPsw,
      };
      var obj= {};
      fetch('/api/addUser.php', {
          method : "POST" , 
          headers : {
            'Content-Type': 'application/json',
          },
          body : JSON.stringify({data})
        })
        .then(response =>response.text())
        .then(response =>{
          obj=JSON.parse(response);
          if(obj){
            enqueueSnackbar('Users updated successfully 👍','success');
            
            resetUserForm()} else {
              enqueueSnackbar('Something was wrong','error');
            }
        })
    }
    return (
        <UserShield>
            <UserItem>
                <DeleteUser/>
            </UserItem>
            <UserItem>
            <form noValidate   encType="multipart/form-data"
                        className={classes.root}
                      autoComplete="off">

                     <TextField
                            id="newUser"
                            size="small"
                            label={'New user'}
                            className="text-field"
                            value={userses.newUser}
                            onChange={handleChange('newUser')}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <i class="fas fa-pencil-alt"></i>
                                </InputAdornment>
                              ),
                            }}
                            /> 
                     <TextField
                            id="newPsw"
                            size="small"
                            label={'User Password'}
                            type="password"
                            className="text-field"
                            value={userses.newPsw}
                            onChange={handleChange('newPsw')}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <i class="fas fa-pencil-alt"></i>
                                </InputAdornment>
                              ),
                            }}
                            /> 
                    <Button variant="contained" color="success" style={{background:'#00db00;'}} onClick={addUser}>
                        Save
                    </Button>
            </form>
            </UserItem>
        </UserShield>
    )
}

export default Users
