import styled from 'styled-components'


export const PriceContainer = styled.div`
    display:flex;
    justify-content:center;
    /* align-items:center; */
    flex-wrap:wrap;
    width:100%;
    max-width:1100px;
`

export const PriceBlocks = styled.div`
    width:50%;
    height:auto;
    padding:15px;
    @media screen and (max-width : 768px) {
        width:100%;
    }
`
export const PriceTitle = styled.h1`
text-shadow: 0px 0px 10px black;
font-size: 1.6rem;
text-align: center;
padding: 40px 0 10px;  
font-weight: 500;
color: #ffffff;
@media screen and (max-width : 768px ){
    
    padding: 20px 0 10px;
}
`
export const PricePage = styled.div`
    display: flex;
    width: 100%;
    min-height: 85vh;
   background: url('/img/price/bg.jpg') 100% 0% no-repeat;
   background-size: cover;
   
  background-attachment: fixed;
    
    margin-top:50px;
    justify-content: center;
`
export const ListContainer = styled.div`
    display: flex;
    flex-direction:column;
    width: 100%;
    justify-content: center;
`
export const PriceListItem = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`

export const ListItem = styled.div`
    padding:10px 10px;
    border-bottom:1px dashed grey;
    font-weight:600;
    font-size:1rem;
    display:flex;
    color:#d0d0d0;
    justify-content:space-between;
    transition:.4s ease;
       
        &:hover {
        background:white;
        color: #857650;
        background: #171717;
    }

    @media screen and (max-width : 768px){
        padding:3px 2px;
        
        font-size:0.9rem;
    }
`
export const ListItemText = styled.span`
    font-weight:600;
`

export const ListItemIcon = styled.span`
    color:#d0d0d0;
   /* &:hover {
        background:white;
        color: #857650;
        background: #171717;
    } */
`