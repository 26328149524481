import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'

const ModalAddServ = (props) => {
    
    


    const [data , setData] = useState({
        services:
            [{
                title_ro:'',
                title_ru:''
            }]
    })

    const sendData = (e) =>{
        e.preventDefault()
        let sending = data.services
        fetch('/api/changeService.php', {
            method : "POST" , 
            headers : {
              'Content-Type': 'application/json',
            },
            body : JSON.stringify(sending)
          })
          .then(response =>response.json())
          .then(response =>{
                if(response===true){props.handleClose()}
                window.location.reload(false);
            })
    }

    useEffect(() => {
        fetch('/api/loadService.php', {
          method : "POST" , 
          headers : {
            'Content-Type': 'application/json',
          },
          body : JSON.stringify()
        })
        .then(response =>response.json())
        .then(response =>{
            setData({services:response})
          })
    }, [])
    

   

    const serv = data.services.map((el,id)=>{
        return(
            <>
            <Typography>Service : {el.title_ro}</Typography> 
            <Grid container spacing={1}>
            
            <Grid item>
                <TextField
                    // size="small"
                    className="text-field"
                    margin="dense"
                    fullWidth
                    value={el.title_ro}
                    name = "title_ro"
                    variant="filled"
                    onChange={event => handleChange(id , event)}
                    InputProps={{
                              startAdornment: (
                                <InputAdornment sx={{mr:1}}>
                                  Ro: 
                                </InputAdornment>
                              ),}}
                />
                </Grid>
            <Grid item>
                <TextField
                    size='small'
                    margin="dense"
                    fullWidth
                    value={el.title_ru}
                    name = "title_ru"
                    variant="filled"
                    onChange={event => handleChange(id , event)}
                    InputProps={{
                              startAdornment: (
                                <InputAdornment sx={{mr:1}}>
                                 Ru:
                                </InputAdornment>
                              ),}}
                />
                </Grid>
            </Grid>
            <Divider />
            </>
        )
    })

    const handleChange = (index , event) =>{
        const servic = [...data.services];
        servic[index][event.target.name] = event.target.value;
        setData({ ...data, servic })
    }

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
    <form onSubmit={sendData}>
    <DialogTitle>Services</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Here You can change 
      </DialogContentText>
      <Divider />
        {serv}
           
    
    </DialogContent>
    <DialogActions>
      <Button onClick={props.handleClose}>Cancel</Button>
      <Button type="submit">Save</Button>
    </DialogActions>
    </form>
  </Dialog>
  )
}

export default ModalAddServ