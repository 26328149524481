import React, { useEffect, useState } from "react";
import {
  InfoBlock,
  InfoContainer,
  InfoHeader,
  Informarion,
  InfoItems,
  ImageBox,
  InfoFrame1,
  InfoFrame2,
  InfoFrame3,
  InfoFrame4,
  InfoImage,
  InfoSubtitle,
  ImageItem,
} from "./InfoEllements";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";

import { useCookies } from "react-cookie";
import axios from "axios";

const InfoSection = () => {
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(["lang"]);

  const [article, setArticle] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const { data } = await axios(`${apiUrl}/article`);
        if (!data.data) return;
        setArticle(data.data);
      } catch (error) {
        console.log("Возникла ошибка : ", error);
      }
    })();
  }, []);
  const server = process.env.REACT_APP_MY_PUBLIC;

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [modalItem, setModalItem] = useState({
    photo: "0.png",
    text_md: "Descriptions",
    text_ru: "Описание",
    tittle_md: "Tittle",
    tittle_ru: "Заголовок",
  });

  const show = (articl) => {
    handleOpen();
    setModalItem({
      photo: `${server}${articl.pictures[0].path}`,
      text_md: articl.text,
      text_ru: articl.text,
      tittle_md: articl.nameTitle,
      tittle_ru: articl.nameTitle,
    });
  };

  return (
    <InfoBlock>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box className="modalBox">
            <ImageItem>
              <ImageBox src={modalItem.photo} />
            </ImageItem>
            <Typography
              id="transition-modal-title"
              color={"#b2904f"}
              variant="h6"
              component="h3"
            >
              {cookies.lang !== "md"
                ? modalItem?.tittle_ru
                : modalItem?.tittle_md}
            </Typography>
            <Typography
              color={"#b2904f"}
              id="transition-modal-description"
              sx={{ mt: 2 }}
              style={{ whiteSpace: "pre-line" }}
            >
              {cookies.lang !== "md" ? modalItem?.text_ru : modalItem?.text_md}
            </Typography>
          </Box>
        </Fade>
      </Modal>
      {article.length === 7 ? (
        <InfoContainer>
          <InfoHeader>
            {cookies.lang !== "md" ? "Наши Услуги" : "Serviciile noastre"}
          </InfoHeader>
          <Informarion>
            <InfoItems>
              <InfoFrame1
                onClick={() => {
                  show(article[0]);
                }}
              >
                <InfoImage src={`${server}${article[0].pictures[0].path}`} />
                <InfoSubtitle>
                  {cookies.lang !== "md"
                    ? article[0].nameTitle
                    : article[0].nameTitle}
                </InfoSubtitle>
              </InfoFrame1>
              <InfoFrame2
                onClick={() => {
                  show(article[1]);
                }}
              >
                <InfoImage src={`${server}${article[1].pictures[0].path}`} />

                <InfoSubtitle>
                  {cookies.lang !== "md"
                    ? article[1].nameTitle
                    : article[1].nameTitle}
                </InfoSubtitle>
              </InfoFrame2>
              <InfoFrame3
                onClick={() => {
                  show(article[2]);
                }}
              >
                <InfoImage src={`${server}${article[2].pictures[0].path}`} />

                <InfoSubtitle>
                  {cookies.lang !== "md"
                    ? article[2].nameTitle
                    : article[2].nameTitle}
                </InfoSubtitle>
              </InfoFrame3>
            </InfoItems>
            <InfoItems>
              <InfoFrame4
                onClick={() => {
                  show(article[3]);
                }}
              >
                <InfoImage src={`${server}${article[3].pictures[0].path}`} />

                <InfoSubtitle>
                  {cookies.lang !== "md"
                    ? article[3].nameTitle
                    : article[3].nameTitle}
                </InfoSubtitle>
              </InfoFrame4>
            </InfoItems>
            <InfoItems>
              <InfoFrame3
                onClick={() => {
                  show(article[4]);
                }}
              >
                <InfoImage src={`${server}${article[4].pictures[0].path}`} />

                <InfoSubtitle>
                  {cookies.lang !== "md"
                    ? article[4].nameTitle
                    : article[4].nameTitle}
                </InfoSubtitle>
              </InfoFrame3>
              <InfoFrame1
                onClick={() => {
                  show(article[5]);
                }}
              >
                <InfoImage src={`${server}${article[5].pictures[0].path}`} />

                <InfoSubtitle>
                  {cookies.lang !== "md"
                    ? article[5].nameTitle
                    : article[5].nameTitle}
                </InfoSubtitle>
              </InfoFrame1>
              <InfoFrame2
                onClick={() => {
                  show(article[6]);
                }}
              >
                <InfoImage src={`${server}${article[6].pictures[0].path}`} />

                <InfoSubtitle>
                  {cookies.lang !== "md"
                    ? article[6].nameTitle
                    : article[6].nameTitle}
                </InfoSubtitle>
              </InfoFrame2>
            </InfoItems>
          </Informarion>
        </InfoContainer>
      ) : (
        <>Данные ошибочны</>
      )}
    </InfoBlock>
  );
};

export default InfoSection;
