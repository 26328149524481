import React  from 'react'
import { FooterSection ,FooterContainer , FooterCards } from './FooterEllements'


const Footer = () => {

    return (
        <>
            <FooterSection>
                <FooterContainer>
                        
                            
                        <FooterCards src="/img/logos.png" />
                </FooterContainer>
            </FooterSection>
        </>
    )
}

export default Footer
