import React, { useState } from "react";
import {
  HeroContainer,
  HeroLogo,
  HeroOrder,
  HeroOrderText,
} from "./HeroElements";

import { useCookies } from "react-cookie";

const HeroSection = () => {
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(["lang"]);
  const [links, setLinks] = useState("https://b531212.alteg.io/");

  return (
    <>
      <HeroContainer id="home">
        <HeroLogo src="/img/logos.png" />
        <HeroOrder href={links}>
          <HeroOrderText>
            {cookies.lang !== "md" ? "Записаться" : "PROGRAMEAZĂ-TE"}
          </HeroOrderText>
        </HeroOrder>
      </HeroContainer>
    </>
  );
};

export default HeroSection;
