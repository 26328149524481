import styled from 'styled-components'

export const BlockWhere = styled.div`
    width: 100%;
    height: auto;
    padding: 20px;
    background: url('img/white_background.jpg') 100% 100% no-repeat;
    background-size: cover;

`

export const ContainerWhere = styled.div`
    display:flex;
    flex-wrap:wrap;
    width:100%;
    max-width: 1100px;
    justify-content: center;
    align-items:  flex-start;
    margin:auto;

`
export const HeaderWhere = styled.h2`
    font-size: 30px;
    font-weight:600;
    text-align: center;

    @media screen and (max-width : 900px) {
        font-size: 25px;
    }
`

export const ItemsWhere = styled.div`
    width:33%;
    /* color:black; */
    /* background: grey; */
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width : 900px) {
        width:100%;
    }
`

export const ImageWhere = styled.img`
    width:40%;
    height:auto;
    border-radius: 10px;
    padding:20px;

    @media screen and (max-width : 900px) {
        width:30%;
        padding:10px;
    }
`

export const ItemHeadWhere = styled.h3`
    margin-bottom: 10px;

    @media screen and (max-width : 900px) {
        margin-bottom: 0px;
    }
`

export const ItemTextWhere = styled.span`
    margin:5px 0;
    @media screen and (max-width : 900px) {
        margin-bottom: 0px;
    }
`

export const Contact = styled.a`
    text-decoration:none;
    color:black;
`

export const WhereMap = styled.div`
    display: flex;
    justify-content: center;
    width:100%;
`

export const SocialsWhere = styled.div`
    width:100%;
    margin-top: 30px;
    display:flex;
    justify-content: center;
`

export const SocialItem = styled.a`
    font-size: 50px;
    margin:10px 30px;
    cursor:pointer;
    transition: .2s ease;
    color:black;
    
    &:hover {transform:scale(1.2);}
`