import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  ListItemText,
  ListItemIcon,
  ListItem,
  PriceBlocks,
  PriceTitle,
  ListContainer,
} from "./PriceListEllements";

import { Shampoo } from "./ListMaterialData";

import { Words } from "../Library/Words";
import { useCookies } from "react-cookie";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    color: "#d0d0d0",
    fontSize: theme.typography.pxToRem(17),

    fontWeight: theme.typography.fontWeightBold,
  },
  listItem: {
    fontSize: "50px",
  },
  accordion: {
    background: "#000000d1",
  },
  icon: {
    color: " #d0d0d0",
  },
}));

const listItemsShampoo = Shampoo.map((Shampoo) => (
  <ListItem key={Shampoo.text}>
    <ListItemText>{Shampoo.text}</ListItemText>
    <ListItemIcon>{Shampoo.price} L</ListItemIcon>
  </ListItem>
));

const ListMaterials = () => {
  const classes = useStyles();
  window.scrollTo(0, 0);
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(["lang"]);

  const [data, setData] = useState([]);
  const [groupe, setGroupe] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const { data } = await axios.get(`${apiUrl}/price/items`);

        if (!data) return;
        setGroupe(data);
      } catch (error) {
        console.log("Возникла ошибка : ", error);
      }
    })();
  }, []);

  const gr = groupe.map((el, id) => {
    return (
      <Accordion className={classes.accordion} key={id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <Typography className={classes.heading}>
            {cookies.lang === "md" ? el.labelEn : el.labelRu}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ListContainer>
            {el.price_list
              ? el.price_list.map((ell) => {
                  return (
                    <ListItem>
                      <ListItemText>
                        {cookies.lang === "md" ? ell.labelEn : ell.labelRu}
                      </ListItemText>
                      <ListItemIcon>{ell.mdl}</ListItemIcon>
                    </ListItem>
                  );
                })
              : ""}
          </ListContainer>
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <PriceBlocks>
      <PriceTitle>{Words[cookies.lang].priceMaterial}</PriceTitle>
      <div className={classes.root}>{gr}</div>
    </PriceBlocks>
  );
};

export default ListMaterials;
