import React from 'react'
import PriceList from '../components/PriceList'

export const Price = () => {
    
    window.scrollTo(0, 0)
    return (
        <>
            <PriceList/>
        </>
    )
}

