import { TextField } from '@material-ui/core';
import { Button, InputAdornment } from '@mui/material';
import React from 'react'



import {  makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';



const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: '10px 10px 10px 0',
        width: '100%',

        color:'black',
        '& Mui-focused':{
          color:'black'
        },
        '& label': {
          color:'Black',
          '&.Mui-focused':{
            color:'black'
          }
        },
        '& fieldset':{
          borderColor:'grey',
          '&:hover , &:focus':{
            borderColor:"grey"
          }
         },
         
      '& textarea':{
        color:'black',
        '&:hover , &:focus':{
          borderColor:"grey"
        }
      },
      '& MuiTextField-root':{
        width:'45%',
      },

        '&.MuiButton-outlined':{
          borderColor:'black',
        }
      
       
      },
      
    },
    
  }));


const AddFeedback = () => {
  const { enqueueSnackbar } = useSnackbar();

    
    const classes = useStyles();
    let reader = new FileReader()
 // eslint-disable-next-line
    const [file,setFile] =React.useState({selectedFile:null});
    const [chosenImg,setChosenImg] = React.useState(false)
    
    const [values, setValues] = React.useState({
        photo:'/' ,
        name:'',
        text:'',
        image:''
      });
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

  const fileSelecter = (prop) => (event) =>{
        let file = event.target.files[0];
        reader.readAsDataURL(file);
          reader.onload = function(){
            setValues({...values,image:reader.result})
      }
        setChosenImg(true);
      }

      const resetForm = () =>{
        setValues({
          photo:'' ,
          name:'',
          text:'',
          image:''
        })
        setChosenImg(false);
      }

    const confirm = (e) =>{
      e.preventDefault();

      let data= {
        
        photo: values.image,
        name:values.name,
        text:values.text,
      };
      var obj= {};
      fetch('/api/addFeedback.php', {
          method : "POST" , 
          headers : {
            'Content-Type': 'application/json',
          },
          body : JSON.stringify({data})
        })
        .then(response =>response.text())
        .then(response =>{
          obj=JSON.parse(response);
          if(obj){
            enqueueSnackbar('Articles updated successfully 👍','success');
            
            resetForm()} else {
              enqueueSnackbar('Something was wrong','error');
            }
        })
    }

    
    return (
        <div>
            <form noValidate   encType="multipart/form-data"
                        className={classes.root}
                      autoComplete="off">
                        <div className="admin-comments">
                        <input
                        values=''
                        accept="image/*"
                           className={classes.input}
                        style={{ display: 'none' }}
                        id="raised-button-file-feedback"
                        type="file"
                        name="file"
                        onChange={fileSelecter()}
                      />
                      
                      <label htmlFor="raised-button-file-feedback" className={chosenImg ? 'btn-image ok':'btn-image'}>
                        <Button variant="raised" component="span" >
                          {chosenImg? 'Uploaded Ok':'Upload'}
                        </Button>
                      </label> 
                     
                    <TextField id="name"
                            label={'Name'}
                            multiline
                            className="admin-comments-text_field"
                            value={values.name}
                            onChange={handleChange('name')}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <i class="fas fa-pencil-alt"></i>
                                </InputAdornment>
                              ),}}
                            />   
                        <TextField id="text"
                            label={'Feedback text'}
                            multiline
                            className="admin-comments-text_field"
                            value={values.text}
                            onChange={handleChange('text')}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <i class="fas fa-pencil-alt"></i>
                                </InputAdornment>
                              ),}}
                            />  
                    </div>
                    
                   
                            
                              <Button onClick={confirm} 
                              >Enter</Button> 
                                           
                 </form>
        </div>
    )
}

export default AddFeedback
