
import { Button} from '@mui/material';
import React from 'react'



import {  makeStyles } from '@material-ui/core/styles';
import 'material-react-toastify/dist/ReactToastify.css';
import { useSnackbar } from 'notistack';


const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: '10px 10px 10px 0',
        width: '100%',

        color:'black',
        '& Mui-focused':{
          color:'black'
        },
        '& label': {
          color:'Black',
          '&.Mui-focused':{
            color:'black'
          }
        },
        '& fieldset':{
          borderColor:'grey',
          '&:hover , &:focus':{
            borderColor:"grey"
          }
         },
         
      '& textarea':{
        color:'black',
        '&:hover , &:focus':{
          borderColor:"grey"
        }
      },
      '& MuiTextField-root':{
        width:'45%',
      },

        '&.MuiButton-outlined':{
          borderColor:'black',
        }
      
       
      },
      
    },
    
  }));


const AddWork = () => {
  const { enqueueSnackbar } = useSnackbar();

    
    const classes = useStyles();
    let reader = new FileReader()

    const [chosenAddImg,setchosenAddImg] = React.useState(false)
    
    const [values, setValues] = React.useState({
        photo:'/' ,
        image:''
      });
    

  const fileAddSelecter = (prop) => (event) =>{
        let file = event.target.files[0];
        reader.readAsDataURL(file);
          reader.onload = function(){
            setValues({...values,image:reader.result})
      }
      setchosenAddImg(true);
      }

      // const resetForm = () =>{
      //   setValues({
      //     photo:'' ,
      //     image:''
      //   })
      // }

    const confirm = (e) =>{
      e.preventDefault();

      let data= {
        
        photo: values.image,
        id: values.id,
        tittleRu:values.tittleRu,
        tittleRo:values.tittleRo,
        textRu:values.textRu,
        textRo:values.textRo,
      };
      var obj= {};
      fetch('/api/addWork.php', {
          method : "POST" , 
          headers : {
            'Content-Type': 'application/json',
          },
          body : JSON.stringify({data})
        })
        .then(response =>response.text())
        .then(response =>{
          obj=JSON.parse(response);
          if(obj){
            enqueueSnackbar('Articles updated successfully 👍','success');
            
          } else {
            enqueueSnackbar('Something was wrong','error');
            
           }
        })
    }

    
    return (
        <div>
            <form noValidate   encType="multipart/form-data"
                        className={classes.root}
                      autoComplete="off">
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <input
                        values=''
                        accept="image/jpg , image/jpeg"
                           className={classes.input}
                        style={{ display: 'none' }}
                        id="raised-button-fileAdd"
                        type="file"
                        name="fileAdd"
                        onChange={fileAddSelecter()}
                      />
                      
                      <label htmlFor="raised-button-fileAdd" style={{width:'70%',display:'flex',justifyContent:"center",margin:'0'}} className={chosenAddImg ? 'btn-image ok':'btn-image'}>
                        <Button variant="raised" component="span" >
                          {chosenAddImg? 'Uploaded Ok':'Upload'}
                        </Button>
                      </label> 
                     
                    </div>
                   
                   
                            
                              <Button onClick={confirm}
                              >Enter</Button> 
                                           
                 </form>
        </div>
    )
}

export default AddWork
