import React, { useEffect, useState } from "react";
import background from "../../img/news.webp";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useCookies } from "react-cookie";

export default function News() {
  const [dataNews, setDataNews] = useState([]);
  const [cookies, setCookie] = useCookies(["lang"]);
  console.log(cookies.language);
  const server = process.env.REACT_APP_MY_PUBLIC;
  useEffect(() => {
    (async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const { data } = await axios.get(`${apiUrl}/news/last`);
        setDataNews(data);
      } catch (error) {
        console.log("Возникла ошибка : ", error);
      }
    })();
  }, []);

  return (
    <Box
      className="news_block"
      style={{
        backgroundImage: `url(${background})`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box mb={12} color={"white"}>
        <Typography variant={"h3"} style={{ fontWeight: 600 }}>
          Beauty NEWS
        </Typography>
      </Box>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <List className="news_card" sx={{ bgcolor: "background.paper" }}>
          {dataNews.map((news) => {
            return (
              <>
                <ListItem
                  alignItems="flex-start flex-wrap"
                  className="news_card_item"
                >
                  <ListItemAvatar style={{ marginRight: 12 }}>
                    <Avatar
                      alt="Envy"
                      variant="rounded"
                      sx={{ width: 200, height: 200 }}
                      src={`${server}${news.pictures?.path}`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{ style: { marginBottom: "24px" } }}
                    primary={
                      <span className="news_title">
                        {cookies.lang && cookies.lang !== "ru"
                          ? news.labelEn
                          : news.labelRu}
                      </span>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {cookies.lang && cookies.lang !== "ru"
                            ? news.textEn
                            : news.textRu}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            );
          })}
        </List>
      </div>
    </Box>
  );
}
