import styled from "styled-components";
export const FooterSection = styled.div`
  background: #f6f6f6;
  width: 100%;
  justify-content: center;
  display: flex;
`;

export const FooterContainer = styled.div`
  width: 100%;
  max-width: 1300px;

  /* margin:10px 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const FooterCards = styled.img`
  width: 40%;
  padding: 10px;

  @media screen and (max-width: 960px) {
    width: 98%;
    height: auto;
  }
`;
