export const Words = {
    'ru' : {
        order : 'Записаться',
        ourWorks : 'Наши работы',
        portfolio : 'Портфолио',
        feedbacks : 'Отзывы', 
        contacts : 'Наши Контакты',
        phone : 'Телефон',
        adress : 'Адрес' , 

        priceService : 'Прайс на услуги',
        priceMaterial : 'Прайс на материалы',
    },
    'md' : {
        order : 'Inscrie-te',
        ourWorks : 'Munca noastra',
        portfolio : 'Portofoliu',
        feedbacks : 'Recenzii', 
        contacts : 'Contactele noastre',
        phone : 'Telefon',
        adress : 'Adresa' , 

        priceService : 'Preț pentru servicii',
        priceMaterial : 'Preț pentru materiale',
    },
}