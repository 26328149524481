import styled from 'styled-components'
import { FaTimes } from 'react-icons/fa'
import { Link as LinkR } from 'react-router-dom'
// import { Link as LinkS } from 'react-scroll'

export const SidebarContainer = styled.aside`
    position:fixed;
    z-index:999;
    width:100%;
    height: 100%;
    background: #0d0d0d;
    display:grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.6s ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '1' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};

`
export const  BtnSwLang = styled.button`
height:100px;
font-size: 32px;
font-weight: 600;
background: none;
border:none;
margin-bottom: 20px;
color:#ffffff;
`

export const CloseIcon = styled(FaTimes)`
color : #fff;
`

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right:1.5rem;
    background:transparent;
    font-size:2rem;
    cursor:pointer;
    outline:none;

`;

export const SidebarWrapper = styled.div`
 color: #fff;
`;

export const SidebarMenu = styled.ul`
    display:grid;
    grid-template-columns:1fr;
    grid-template-rows : repeat(6 , 120px);
    align-items:center;

    @media screen and (max-width : 768px){
        grid-template-rows: repeat(5 , 80px)
    }
    @media (orientation: landscape) {
        grid-template-rows: repeat(4 , 60px)
    }

`;

export const SidebarLink = styled(LinkR)`
    display:flex;
    align-items:components;
    justify-content:center;
    font-size:2rem;
    text-decoration:none;
    list-style:none;
    transition : 0.4s ease;
    text-decoration:none;
    color: #fff;
    cursor:pointer;
    &:hover{
        color:#bf9f01;
        transition:.4s ease;
    }
`;