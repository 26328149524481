import styled from "styled-components";

export const HeroContainer = styled.div`
  background: url("/img/logs.webp") center no-repeat;
  background-color: #cccccc;
  background-size: cover;
  width: 100%;
  padding: 50% 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  position: relative;
  z-index: 1;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    background-image: url("/img/logs.webp");
  }
`;

export const HeroLogo = styled.img`
  background: none;
  margin-top: -100px;
  width: 50%;
  height: auto;
  z-index: 2;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const HeroOrder = styled.a`
  text-decoration: none;
  background: #857650;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  border-radius: 2px;
  margin-top: 150px;
  margin-bottom: -200px;
`;

export const HeroOrderText = styled.span`
  font-size: 32px;
  font-weight: normal;
  color: black;
  text-decoration: underline;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;
