import styled from 'styled-components';

export const Gallery = styled.div`
background: url('/img/black_background.jpg') 100% 100% no-repeat ;
background-size: cover;
background-attachment: fixed;
 padding:100px 15px;
 -webkit-column-width:25%;
 -moz-column-width:25%;
 column-width: 25%;

 -webkit-column-count:4;
 -moz-column-count:4;
 column-count:4;
 transition: 0.4s ease;

 @media screen and (max-width : 960px){
    padding:60px 10px;

    -webkit-column-count:2;
    -moz-column-count:2;
    column-count:2;
 }
 @media screen and (max-width : 460px){
    padding:60px 5px;

    -webkit-column-count:1;
    -moz-column-count:1;
    column-count:1;
 }
`


export const Pictures = styled.div`
margin-bottom: 15px;
overflow: hidden;
    border-radius: 10px;
`

export const Images = styled.img`
    width:100%;
box-shadow:2px 2px 5px 0px #00000066;
    transition: .2s ease;        
    filter:contrast(1);
    &:hover {
        
        filter:contrast(1.5);
        cursor: pointer;
    }
`
export const ModImg = styled.img`
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing:border-box;
    padding: 20px 0;
    margin: 0 auto;

`

export const Models = styled.div`
    width:100%;
    z-index: 100;
    height: 100vh;
    position: fixed;
    
    top:0;
    left:0;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: #191919;
    transition: .4s ease;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
`
