import React from "react";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import PortfolioSection from "../components/PortfolioSection";
import Feedbacks from "../components/Feedbacks";
import WhereWe from "../components/WhereWe";
import News from "../components/HomePage/News";

export const Home = () => {
  window.scrollTo(0, 0);

  return (
    <>
      <HeroSection />
      <PortfolioSection />
      <InfoSection />
      <Feedbacks />
      <News />
      <WhereWe />
    </>
  );
};
