import { Box, Button,  TextField } from '@material-ui/core';

import React , { useEffect} from 'react'

import {  makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@mui/material';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: '10px 10px 10px 0',
        width: '100%',

        color:'black',
        '& Mui-focused':{
          color:'black'
        },
        '& label': {
          color:'Black',
          '&.Mui-focused':{
            color:'black'
          }
        },
        '& fieldset':{
          borderColor:'grey',
          '&:hover , &:focus':{
            borderColor:"grey"
          }
         },
         
      '& textarea':{
        color:'black',
        '&:hover , &:focus':{
          borderColor:"grey"
        }
      },
      '& MuiTextField-root':{
        width:'45%',
      },

        '&.MuiButton-outlined':{
          borderColor:'black',
        }
      
       
      },
      
    },
    
  }));



const DeleteUser = () => {
  
  const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [loadUser, setLoadUser] = React.useState({
       
      });


    const resetForm = () =>{
        setLoadUser([{
            userId:'',
        }]);

        
        setTimeout(() => window.location.reload(), 1000);
    }

    const deleteUser = (e) => {
        console.log(loadUser)
        e.preventDefault();
    
      let data= {
        loadUser:loadUser.userId,
      };
      var obj= {};
      fetch('/api/deleteUser.php', {
          method : "POST" , 
          headers : {
            'Content-Type': 'application/json',
          },
          body : JSON.stringify({data})
        })
        .then(response =>response.text())
        .then(response =>{
          obj=JSON.parse(response);
          if(obj){
            enqueueSnackbar('Users updated successfully 👍','success');
            resetForm()
          } else {
            enqueueSnackbar('Something was wrong','error');
          }})
          
    }


    const [userList,setUserList] = React.useState([{
        
    }])

    useEffect(() =>{
        let data={
          
       }
        fetch('/api/loadDataUsers.php', {
          method : "POST" , 
          headers : {
            'Content-Type': 'application/json',
          },
          body : JSON.stringify({data})
        })
        .then(response =>response.json())
        .then(response =>{

            setUserList(response);
        })
      }, [])

    return (
        <>
        <div style={{width:'100%'}}>
        <form noValidate   encType="multipart/form-data"
                        className={classes.root}
                      autoComplete="off">
        Remove users
        <Autocomplete id="firma"
                          options={userList}
                          size="small"
                          onChange={(props,option) => option ? setLoadUser(prevState => ({...prevState,userId:option.id})) : setLoadUser(prevState => ({...prevState,userId:0}))}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                        //   classes={{ paper: classes.paper }}  
                          renderOption={(props, option) => (
                            
                            <Box component="li" {...props}>
                              {option.label} 
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value=""
                              required 
                              placeholder="Chose User from list"
                            />
                          )}
                        />
                <Button variant="contained" color="error" style={{background:'#ff5656;'}}  onClick={deleteUser}>
                        Delete
                    </Button>
        </form>
        </div>
        </>
    )
}

export default DeleteUser
