import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment,  MenuItem,  TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'

import DeleteIcon from '@mui/icons-material/Delete';

const ModalAddServItem = (props) => {
    
    const [service, setService] = React.useState('0');


    const [data , setData] = useState({
        services:
            [{  id:'',
                title_ro:'',
                title_ru:''
            }],
    })
    const [value , setValue] = useState({
            services:
                [{  id:'',
                    type_service:'',
                    title_ro:'',
                    title_ru:'',
                    price : ''
                }],
        })

    const sendData = (e) =>{
        e.preventDefault()
        let sending = {type:service , data : value.services}
        fetch('/api/changeServiceItem.php', {
            method : "POST" , 
            headers : {
              'Content-Type': 'application/json',
            },
            body : JSON.stringify(sending)
          })
          .then(response =>response.json())
          .then(response =>{
            console.log(response)
                if(response===true){props.handleClose()}
                // window.location.reload(false);
            })
    }

    useEffect(() => {
        fetch('/api/loadService.php', {
          method : "POST" , 
          headers : {
            'Content-Type': 'application/json',
          },
          body : JSON.stringify()
        })
        .then(response =>response.json())
        .then(response =>{
            setData({services:response})
          })
    }, [])
    

    useEffect(()=>{
      fetch('/api/loadServiceItem.php', {
        method : "POST" , 
        headers : {
          'Content-Type': 'application/json',
        },
        body : JSON.stringify(service)
      })
      .then(response =>response.json())
      .then(response =>{
          setValue({services:response})
        })
    },[service])

    const serv = value.services.map((el,id)=>{
        return(
            <div key={id}>
            <Typography>Service : {el.title_ro}</Typography> 
            <Grid container spacing={1}>
            
            <Grid item xs={4}>
                <TextField
                    // size="small"
                    className="text-field"
                    margin="dense"
                    fullWidth
                    required
                    value={el.title_ro}
                    name = "title_ro"
                    variant="filled"
                    onChange={event => handleChange(id , event)}
                    InputProps={{
                              startAdornment: (
                                <InputAdornment sx={{mr:1}}>
                                  Ro: 
                                </InputAdornment>
                              ),}}
                />
                </Grid>
            <Grid item xs>
                <TextField
                    size='small'
                    margin="dense"
                    fullWidth
                    required
                    value={el.title_ru}
                    name = "title_ru"
                    variant="filled"
                    onChange={event => handleChange(id , event)}
                    InputProps={{
                              startAdornment: (
                                <InputAdornment sx={{mr:1}}>
                                 Ru:
                                </InputAdornment>
                              ),}}
                />
                </Grid>
            <Grid item xs={2}>
                <TextField
                    size='small'
                    margin="dense"
                    fullWidth
                    required
                    value={el.price}
                    name = "price"
                    variant="filled"
                    onChange={event => handleChange(id , event)}
                    InputProps={{
                              startAdornment: (
                                <InputAdornment sx={{mr:1}}>
                                 lei:
                                </InputAdornment>
                              ),}}
                />
                </Grid>
                <Grid item>
                  <IconButton aria-label="delete" onClick={()=>{delServ(id)}} >
                    <DeleteIcon color='error'/>
                  </IconButton>
                </Grid>
            </Grid>
            <Divider />
            </div>
        )
    })

    const handleChange = (index , event) =>{
        const servic = [...value.services];
        servic[index][event.target.name] = event.target.value;
        setValue({ ...value, servic })
    }

    const handleChangeServ = (e)=>{
      setService(e.target.value)
      
    }
   
    const addServ = () =>{
      let el ={id:'' , type_service : service , title_ru :'' , title_ro : '' , }
      let arr = value.services;
      arr.push(el)
      setValue({...value , arr})
    }

    const delServ = (id) =>{
      let arr = value.services;
      arr.splice(id, 1);
      setValue({...value , arr})
    }

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="xl" fullWidth="true" scroll='body'>
    <form onSubmit={sendData}>
    <DialogTitle>Service Item</DialogTitle>
    <DialogContent >
      <Divider />
    

      <TextField
          id="filled-select-currency"
          select
          value={service}
          onChange={handleChangeServ}
          helperText="Please select your service"
          variant="filled"
        ><MenuItem style={{display: 'flex'}}  value='0' disabled>Select service</MenuItem>
          {data.services.map((option) => (
            <MenuItem style={{display: 'flex'}} key={option.title_ro} value={option.id}>
              {option.title_ro}
            </MenuItem>
          ))}
        </TextField>
        {serv}
           
        <Button variant="outlined" onClick={addServ}>
            Add
        </Button>    
    </DialogContent>
    <DialogActions>
      <Button onClick={props.handleClose}>Cancel</Button>
      <Button type="submit">Save</Button>
    </DialogActions>
    </form>
  </Dialog>
  )
}

export default ModalAddServItem