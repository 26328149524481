import React , {useState} from 'react'

import {Switch , Route, Redirect } from 'react-router-dom'

import {Home} from './pages';
import {Price} from './pages/Price';
import {Vacancy} from './pages/Vacancy';
import {OurWork} from './pages/OurWork';

import Sidebar from './components/Sidebar'
import Navbar from './components/Navbar'

import Footer from './components/Footer'

import { useCookies } from 'react-cookie';
import Loghin from './pages/Loghin';
import Admin from './pages/Admin';


export const useRoutes = () =>{
     // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(['permission']);
  
    const [isOpen , setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    }
    
        return (
            <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <Switch>
                <Route path="/"  exact >
                    
                    <Home />
                    <Footer/>
                </Route>
                <Route path="/vacancy" >
                    <Vacancy />
                    <Footer/>
                </Route>
                <Route path="/work" >
                    <OurWork />
                    <Footer/>
                </Route>
                <Route path="/price"  >
                    <Price />
                    <Footer/>
                </Route>
                
                {(cookies.permission==='10' ||  cookies.permission==='5') ?  <Route path="/admin"  ><Admin/></Route> : <Route path="/admin"  ><Loghin /></Route> }
                <Redirect to="/" />
                
            </Switch>
            
            </>
        )
    
}