import React from "react";
import {
  BlockWhere,
  ContainerWhere,
  HeaderWhere,
  ImageWhere,
  ItemHeadWhere,
  ItemsWhere,
  ItemTextWhere,
  Contact,
  WhereMap,
  SocialsWhere,
  SocialItem,
} from "./WhereWeComponents";

import { useCookies } from "react-cookie";
const WhereWe = () => {
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(["lang"]);
  return (
    <>
      <BlockWhere>
        <HeaderWhere>
          {cookies.lang !== "md"
            ? "Где находится Envy Beauty Salon"
            : "Unde pot gasi Envy Beauty Salon"}
        </HeaderWhere>
        <ContainerWhere>
          <ItemsWhere>
            <ImageWhere src="/img/png/call.png" />
            <ItemHeadWhere>
              {cookies.lang !== "md" ? "Свяжитесь с нами" : "Contactează-ne"}
            </ItemHeadWhere>
            <ItemTextWhere>
              <Contact href="tel:+37378552233" targer="_blank">
                (+373) 785 52 233
              </Contact>
            </ItemTextWhere>
            <ItemTextWhere>
              <Contact href="mailto:beautysalonenvy@gmail.com" targer="_blank">
                beautysalonenvy@gmail.com
              </Contact>
            </ItemTextWhere>
          </ItemsWhere>
          <ItemsWhere>
            <ImageWhere src="/img/png/map.png" />
            <ItemHeadWhere>
              {cookies.lang !== "md" ? "Наш Адрес" : "Adresa noastră"}
            </ItemHeadWhere>
            <ItemTextWhere>
              <Contact
                href="https://goo.gl/maps/tX9jjZGEBuTCYzp77"
                targer="_blank"
              >
                Str. Nicolae Costin , 52
              </Contact>
            </ItemTextWhere>
            <ItemTextWhere>
              <Contact
                href="https://goo.gl/maps/tX9jjZGEBuTCYzp77"
                targer="_blank"
              >
                or.Chisinau , Moldova
              </Contact>
            </ItemTextWhere>
          </ItemsWhere>
          <ItemsWhere>
            <ImageWhere src="/img/png/calend.png" />
            <ItemHeadWhere>
              {cookies.lang !== "md" ? "График работы" : "Program de lucru"}
            </ItemHeadWhere>
            <ItemTextWhere>
              {cookies.lang !== "md"
                ? "Понедельник : Выходной"
                : "Luni : Zi de odihnă"}
            </ItemTextWhere>
            <ItemTextWhere>
              {cookies.lang !== "md"
                ? "Вторник : c 09:00 до 19:00"
                : "Marţi : 09:00 - 19:00"}
            </ItemTextWhere>
            <ItemTextWhere>
              {cookies.lang !== "md"
                ? "Среда : c 09:00 до 19:00"
                : "Miercuri : 09:00 - 19:00"}
            </ItemTextWhere>
            <ItemTextWhere>
              {cookies.lang !== "md"
                ? "Четверг : c 09:00 до 19:00"
                : "Joi : 09:00 - 19:00"}
            </ItemTextWhere>
            <ItemTextWhere>
              {cookies.lang !== "md"
                ? "Пятница : c 09:00 до 19:00"
                : "Vineri : 09:00 - 19:00"}
            </ItemTextWhere>
            <ItemTextWhere>
              {cookies.lang !== "md"
                ? "Суббота : c 09:00 до 19:00"
                : "Sâmbătă : 09:00 - 19:00"}
            </ItemTextWhere>
            <ItemTextWhere>
              {cookies.lang !== "md"
                ? "Воскресенье : c 09:00 до 19:00"
                : "Duminică : 09:00 - 19:00"}
            </ItemTextWhere>
          </ItemsWhere>

          <WhereMap>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10874.780008705915!2d28.763515449736122!3d47.046215384558565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDfCsDAyJzM1LjEiTiAyOMKwNDYnMTUuNyJF!5e0!3m2!1sru!2s!4v1631777802482!5m2!1sru!2s"
              width="600"
              height="450"
              title="map"
              loading="lazy"
            ></iframe>
          </WhereMap>

          <SocialsWhere>
            <SocialItem
              href="https://www.instagram.com/envy.beautysalon/"
              target="_blank"
            >
              <i class="fab fa-instagram-square"></i>
            </SocialItem>
            <SocialItem
              href="viber://chat?number=%2B37378552233"
              target="_blank"
            >
              <i class="fab fa-viber"></i>
            </SocialItem>
            <SocialItem
              target="_blank"
              href="https://www.facebook.com/ENVY-Beauty-Salon-107144934761236/"
            >
              <i class="fab fa-facebook-square"></i>
            </SocialItem>
          </SocialsWhere>
        </ContainerWhere>
      </BlockWhere>
    </>
  );
};

export default WhereWe;
