import React, { useEffect, useState } from "react";
import {
  Portfolio,
  PortfolioContainer,
  PortfolioHead,
  PortfolioImages,
  PortfolioImg,
  PortfolioImage,
} from "./PortfolioEllements";

import { useCookies } from "react-cookie";
import axios from "axios";

const PortfolioSection = () => {
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(["lang"]);
  const [images, setImage] = useState(["0.png"]);

  const server = process.env.REACT_APP_MY_PUBLIC;
  useEffect(() => {
    (async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const { data } = await axios.get(`${apiUrl}/work/last`);
        const dataArray = data.map((pic) => `${server}${pic.pictures.path}`);
        setImage(dataArray);
      } catch (error) {
        console.log("Возникла ошибка : ", error);
      }
    })();
  }, []);

  const link = "/img/work/";
  const listItems = images.map((number) => (
    <PortfolioImage>
      <PortfolioImg src={number} alt="last_our_works" />
    </PortfolioImage>
  ));

  return (
    <>
      <Portfolio>
        <PortfolioContainer>
          <PortfolioHead>
            {cookies.lang !== "md" ? "Портфолио" : "Portfolio"}
          </PortfolioHead>
          <PortfolioImages>{listItems}</PortfolioImages>
        </PortfolioContainer>
      </Portfolio>
    </>
  );
};

export default PortfolioSection;
