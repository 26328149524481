import styled from "styled-components";

export const Admins = styled.div`
    width:100%;
    /* display: flex; */
    padding:100px;
    background:grey;
    min-height: 100vh;

    @media screen and (max-width : 960px ){
        padding:50px 0 0;
    }
` 


export const UserShield = styled.div`
    width:100%;
    display:flex;
    justify-content: space-between;
    padding: 8px;

`

export const UserItem = styled.div`
    width:48%;
    display:flex;
    justify-content: center;
    align-items: center;
    background: #ededed;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 1px 1px 1px 1px #cbcbcb;
`