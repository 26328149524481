import { TextField } from '@material-ui/core';
import { Button, InputAdornment } from '@mui/material';
import React from 'react'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import {  makeStyles } from '@material-ui/core/styles';


import 'material-react-toastify/dist/ReactToastify.css';
import { useSnackbar } from 'notistack';


const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: '10px 10px 10px 0',
        width: '100%',

        color:'black',
        '& Mui-focused':{
          color:'black'
        },
        '& label': {
          color:'Black',
          '&.Mui-focused':{
            color:'black'
          }
        },
        '& fieldset':{
          borderColor:'grey',
          '&:hover , &:focus':{
            borderColor:"grey"
          }
         },
         
      '& textarea':{
        color:'black',
        '&:hover , &:focus':{
          borderColor:"grey"
        }
      },
      '& MuiTextField-root':{
        width:'45%',
      },

        '&.MuiButton-outlined':{
          borderColor:'black',
        }
      
       
      },
      
    },
    
  }));


const ArticlesAdmin = () => {
  const { enqueueSnackbar } = useSnackbar();
    
    
    const classes = useStyles();
    let reader = new FileReader()
   // eslint-disable-next-line
    const [file,setFile] =React.useState({selectedFile:null});
    const [chosenImg,setChosenImg] = React.useState(false)
    
    const [values, setValues] = React.useState({
        photo:'/' ,
        id: '',
        tittleRu:'',
        tittleRo:'',
        textRu:'',
        textRo:'',
        image:''
      });
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

  const fileSelecter = (prop) => (event) =>{
        let file = event.target.files[0];
        reader.readAsDataURL(file);
          reader.onload = function(){
            setValues({...values,image:reader.result})
      }
        setChosenImg(true);
      }

      const resetForm = () =>{
        setValues({
          photo:'' ,
          id: '',
          tittleRu:'',
          tittleRo:'',
          textRu:'',
          textRo:'',
          image:''
        })
      }

    const confirm = (e) =>{
      e.preventDefault();

      let data= {
        
        photo: values.image,
        id: values.id,
        tittleRu:values.tittleRu,
        tittleRo:values.tittleRo,
        textRu:values.textRu,
        textRo:values.textRo,
      };
      var obj= {};
      fetch('/api/changeArticles.php', {
          method : "POST" , 
          headers : {
            'Content-Type': 'application/json',
          },
          body : JSON.stringify({data})
        })
        .then(response =>response.text())
        .then(response =>{
          obj=JSON.parse(response);
          if(obj){
            enqueueSnackbar('Articles updated successfully 👍','success');
            resetForm()} else {
              enqueueSnackbar('Something was wrong','error');
            }
        })
    }

    
    return (
        <div>
            <form noValidate   encType="multipart/form-data"
                        className={classes.root}
                      autoComplete="off">
                        <div style={{display:'flex',alignItems:'center'}}>
                        <input
                        values=''
                        accept="image/*"
                           className={classes.input}
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        name="file"
                        onChange={fileSelecter()}
                      />
                      
                      <label htmlFor="raised-button-file" className={chosenImg ? 'btn-image ok':'btn-image'}>
                        <Button variant="raised" component="span" >
                          {chosenImg? 'Uploaded Ok':'Upload photo here'}
                        </Button>
                      </label> 
                     <FormControl sx={{ m: 1, minWidth: 80 }}>      
                     <InputLabel id="article">Articles</InputLabel>
                    <Select
                        labelId="article"
                        id="article"
                        size="small"
                        value={values.id}
                        onChange={handleChange('id')}
                        style={{width:'200px'}}
                        label="Articles"
                        >
                        <MenuItem style={{display: 'flex'}} value={1}>Article 1 </MenuItem>
                        <MenuItem style={{display: 'flex'}} value={2}>Article 2 </MenuItem>
                        <MenuItem style={{display: 'flex'}} value={3}>Article 3 </MenuItem>
                        <MenuItem style={{display: 'flex'}} value={4}>Article 4 </MenuItem>
                        <MenuItem style={{display: 'flex'}} value={5}>Article 5 </MenuItem>
                        <MenuItem style={{display: 'flex'}} value={6}>Article 6 </MenuItem>
                        <MenuItem style={{display: 'flex'}} value={7}>Article 7 </MenuItem>
                    </Select>  
                    </FormControl>
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <TextField id="tittleRu"
                            size="small"
                            label={'Tittle Ru'}
                            multiline
                            className="text-field"
                            value={values.tittleRu}
                            onChange={handleChange('tittleRu')}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <i class="fas fa-pencil-alt"></i>
                                </InputAdornment>
                              ),}}
                            />   
                        <TextField id="tittleRo"
                            size="small"
                            label={'Tittle Ro'}
                            multiline
                            className="text-field"
                            value={values.tittleRo}
                            onChange={handleChange('tittleRo')}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <i class="fas fa-pencil-alt"></i>
                                </InputAdornment>
                              ),}}
                            />  
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                   <TextField
                            id="textRu"
                            size="small"
                            label={'Text Ru'}
                            multiline
                            className="text-field"
                            value={values.textRu}
                            onChange={handleChange('textRu')}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <i class="fas fa-pencil-alt"></i>
                                </InputAdornment>
                              ),
                            }}
                            />  
                   <TextField
                            id="textRo"
                            size="small"
                            label={'Text Ro'}
                            multiline
                            className="text-field"
                            value={values.textRo}
                            onChange={handleChange('textRo')}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <i class="fas fa-pencil-alt"></i>
                                </InputAdornment>
                              ),
                            }}
                            />
                            </div>  
                   
                            
                              <Button onClick={confirm}
                              >Enter</Button> 
                                           
                 </form>
        </div>
    )
}

export default ArticlesAdmin
