import React from 'react'

import { useCookies } from 'react-cookie';
import {Admins} from '../components/Admin/AdminEllements'
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArticlesAdmin from '../components/Admin/ArticlesAdmin';
import AddWork from '../components/Admin/AddWork';
import AddFeedback from '../components/Admin/AddFeedback';
import Button from '@mui/material/Button';
import Users from '../components/Admin/Users';
import { Divider, Grid } from '@mui/material';
import ModalAddServ from '../components/Admin/ModalAddServ';
import { useState } from 'react';
import ModalAddServItem from '../components/Admin/ModalAddServItem';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={'>'}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));


const Admin = () => {
    const [cookies, setCookie] = useCookies(['permission']);
    const [modal , setModal] = useState({
      servGroup : false , 
      servItem : false ,
      itemGroup : false ,
      items : false 
    })
    const exit = () =>{
        setCookie('permission','',{ path: '/' })
        setCookie('id_user','',{ path: '/' })
        window.location.reload()
    }

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const closeModal = () =>{
      setModal({
        servGroup : false , 
        servItem : false ,
        itemGroup : false ,
        items : false 
      })
    }

    return (
        <Admins>
          <ModalAddServ open={modal.servGroup} handleClose={closeModal} />
          <ModalAddServItem open={modal.servItem} handleClose={closeModal} />

             <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Redactor Articles (Редактировать карточки)</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <ArticlesAdmin/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Adding Photos (Добавить фото) *.jpg</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <AddWork/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Adding Comments (Добавить коментарий) </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AddFeedback />
        </AccordionDetails>
      </Accordion>
      {cookies.permission==="10" && 
       <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
       <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
         <Typography>User options (Пользователи)</Typography>
       </AccordionSummary>
       <AccordionDetails>
         <Users/>
       </AccordionDetails>
       
      </Accordion>
       }

      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel4d-header">
          <Typography>Price options (Услуги/Товары и Цены)</Typography>
        </AccordionSummary>

        <AccordionDetails>
            <Divider sx={{mb:2}}>Services (Услуги)</Divider>
          <Grid container spacing={1} sx={{mb:2}}>
            <Grid item>
              <Button color="primary" variant="outlined" onClick={()=>{setModal({...modal ,servGroup : true })}}>Name services group</Button>
            </Grid>
            <Grid item>
              <Button color="primary" variant="outlined" onClick={()=>{setModal({...modal ,servItem : true })}}>Set name and price</Button>
            </Grid>
            </Grid>
            <Divider sx={{mb:2}}>Items (Товары)</Divider>
            <Grid container spacing={1}>
            <Grid item>
              <Button color="info" variant="outlined" disabled onClick={()=>{setModal({...modal ,itemGroup : true })}}>Name items group</Button>
            </Grid>
            <Grid item>
              <Button color="info" variant="outlined" disabled onClick={()=>{setModal({...modal ,items : true })}}>Name items</Button>
            </Grid>
          </Grid>
        
        </AccordionDetails>
        
       </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>Another options (Другое)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button color="error" variant="outlined" onClick={exit}>Exit</Button>
        </AccordionDetails>
        
       </Accordion>
        </Admins>
    )
}

export default Admin
