import styled from 'styled-components';
import {FaArrowAltCircleRight,FaArrowAltCircleLeft} from 'react-icons/fa'


export const ArrowLeft = styled(FaArrowAltCircleLeft)`
    z-index: 10;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 10px;
    top: 50%;
    cursor: pointer;

    transition:.3s ease;
    &:hover{
        color:#bf9f01;
    }
`;
export const ArrowRight = styled(FaArrowAltCircleRight)`

    z-index: 10;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 10px;
    top: 50%;
    cursor: pointer;
    transition:.3s ease;
    &:hover{
        color:#bf9f01;
    }
`;

export const FeedbackBlock = styled.div`
    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top:30px;
    background:#DFDFDF;
`
export const FeedbackLine = styled.div`
        width: 80%;
    text-align: center;
    height:1px;
    background:#a8a8a8a3;
`
export const FeedbackHeader = styled.h2`
        width: 100%;
    text-align: center;
    font-size: 2.2rem;
`

export const FeedbackSliders = styled.div`
    min-width: 90%;
    height: 450px;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    
    @media screen and (max-width : 960px) {
        height: auto;
    }
`

export const FeedbackPeopleImage = styled.div`
    width: 30%;
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
    @media screen and (max-width : 960px) {
        width: 50%; 
        margin-bottom:30px;
    }
`
export const ImageField = styled.div`
    width: 270px;
    height: 370px;
    overflow:hidden;
    border-radius:10px;
    box-shadow: 0 0 10px 10px #b9b9b9, inset 0 0 10px 10px #6d6d6d;

`
export const FeedbackImage = styled.img`
    
    height:100%;
    width:100%;
    object-fit: cover;

`

export const FeedbackText = styled.div`
    width: 40%;
    display:block;
    
    text-align: center;
    @media screen and (max-width : 960px) {
        width: 90%; 
        
        margin-bottom: 30px;
    }
`

export const FeedbackTextHeader = styled.h2`
   font-size:42px; 
   @media screen and (max-width : 960px) {
         font-size:32px; 
    }
`

export const FeedbackTextSubtitle = styled.h3`
   font-size:24px;
   font-weight:normal;
   @media screen and (max-width : 960px) {
         font-size:1.4rem; 
    }
   @media screen and (max-width : 768px) {
         font-size:1rem; 
    }
`
export const FeedbackIndicators = styled.div`
   width:30px;
   height: 30px;
   background:black;
   border-radius:50%;
`