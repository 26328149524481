import styled from "styled-components";

export const VacancyContainer = styled.div`
  background: url("img/marble_background.jpg") center no-repeat;
  background-color: #f6f6f6;
  background-size: cover;
  min-height: 90vh;
  display: flex;
  justify-content: space-around;
  padding: 100px;

  @media screen and (max-width: 960px) {
    padding: 40px 10px;
    justify-content: start;
    flex-direction: column;
  }
`;

export const VacancyItem = styled.div`
  border-radius: 3px;
  background-color: #f6f6f6;
  width: 45%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 3px 10px;
  box-shadow: 0px 0px 0px #00000066, inset 0px 0px 10px #00000066;
  position: relative;
  color: #b2904f;
  font-weight: 500;
  font-size: 28px;
  transition: 0.5s ease;

  @media screen and (max-width: 960px) {
    margin: 20px 0;
    width: auto;
    height: calc(auto + 100px);
  }

  &:hover {
    box-shadow: 5px 5px 10px #00000066, inset 0px 0px 10px #00000066;
  }
`;

export const VacancyItemHead = styled.span`
  color: #b2904f;
  font-size: 28px;

  @media screen and (max-width: 960px) {
    font-size: 22px;
  }
`;

export const VCall = styled.a`
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  text-align: center;
  line-height: 50px;
  bottom: 10px;
  color: #b2904f;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 18px;
  transition: 0.4s ease;

  &:hover {
    background: grey;
  }
`;

export const Puncts = styled.span`
  margin-top: 10px;
  color: #727272;
  font-size: 18px;
  @media screen and (max-width: 960px) {
    font-size: 14px;
  }
`;

export const PunctsAns = styled.span`
  color: #b2904f;
`;

export const Text = styled.span`
  margin: 10px 5px;
  font-size: 20px;
  font-weight: 500;
`;
export const TextAd = styled.span`
  margin: 2px;
  font-size: 16px;
  font-weight: 500;
`;
