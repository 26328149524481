import React from 'react'
import SidebarSocial from './SidebarSocial'
import { SidebarContainer , Icon , CloseIcon ,
         SidebarWrapper , SidebarMenu , SidebarLink ,  BtnSwLang} from './SidebarElements'

import { useCookies } from 'react-cookie';
import './Sidebar.css'

const Sidebar = ({isOpen,toggle}) => {
    
  const [cookies, setCookie] = useCookies(['lang']);
  let changeLang = () =>{
    if (cookies.lang==='md'){setCookie('lang','ru',{ path: '/' })} else {setCookie('lang','md',{ path: '/' })}
    
}
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="price" onClick={toggle}>
                    {cookies.lang!=='md' ? 'Цены' : 'Price'}
                    </SidebarLink>
                    <SidebarLink to="work" onClick={toggle}>
                    {cookies.lang!=='md' ? 'Наши работы' : 'Our Work'}
                    </SidebarLink>
                    <SidebarLink to="vacancy" onClick={toggle} >
                    {cookies.lang!=='md' ? 'Вакансии' : 'Vacancy'}
                    </SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
            <BtnSwLang  onClick={changeLang}>{cookies.lang==='md' ? 'RU' : 'MD'}</BtnSwLang>
            <SidebarSocial onClick={toggle} />
        </SidebarContainer>
    )
}

export default Sidebar
