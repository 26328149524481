import React, { useState, useEffect } from "react";
import {
  FeedbackSliders,
  FeedbackPeopleImage,
  FeedbackImage,
  FeedbackText,
  FeedbackTextHeader,
  FeedbackTextSubtitle,
  ArrowRight,
  ArrowLeft,
} from "./FeedbacksEllements";

import {
  FeedbackBlock,
  FeedbackHeader,
  ImageField,
  FeedbackLine,
} from "./FeedbacksEllements";

import "./Feedback.css";

import { useCookies } from "react-cookie";
import axios from "axios";

const Feedbacks = () => {
  const [lists, setLists] = useState();
  //   useEffect(() => {
  //     let myObject;
  //     let data = {};

  //     fetch("/api/feedback.php", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ data }),
  //     })
  //       .then((response) => response.text())
  //       .then((response) => {
  //         myObject = Object.values(JSON.parse(response));
  //         setLists(myObject);
  //       });
  //   }, []);

  useEffect(() => {
    (async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const { data } = await axios.get(`${apiUrl}/review`);
        if (!data.data) return;
        setLists(data.data);
      } catch (error) {
        console.log("Возникла ошибка : ", error);
      }
    })();
  }, []);

  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(["lang"]);
  const [current, setCurrent] = useState(0);
  const server = process.env.REACT_APP_MY_PUBLIC;

  const nextSlide = () => {
    setCurrent(current === lists.length - 1 ? 0 : current + 1);
  };
  const prevSlide = () => {
    setCurrent(current === 0 ? lists.length - 1 : current - 1);
  };

  if (!Array.isArray(lists) || lists.length <= 0) {
    return <></>;
  }

  return (
    <>
      <FeedbackBlock>
        <FeedbackHeader>
          {cookies.lang !== "md" ? "Отзывы" : "Recenzii"}
        </FeedbackHeader>
        <FeedbackLine />
        <FeedbackSliders>
          <ArrowRight onClick={nextSlide} />
          <ArrowLeft onClick={prevSlide} />
          {lists.map((slide, index) => {
            const link = `${server}${slide.pictures[0].path}`;
            const name = slide.nameTitle;
            const description = slide.text;
            return (
              <div
                className={index === current ? "slide active" : "slide"}
                key={index}
              >
                {index === current && (
                  <>
                    <FeedbackText>
                      <FeedbackTextHeader>{name}</FeedbackTextHeader>
                      <FeedbackTextSubtitle>{description}</FeedbackTextSubtitle>
                    </FeedbackText>
                    <FeedbackPeopleImage>
                      <ImageField>
                        <FeedbackImage src={link} alt="Person_Image" />
                      </ImageField>
                    </FeedbackPeopleImage>
                  </>
                )}
              </div>
            );
          })}
        </FeedbackSliders>
      </FeedbackBlock>
    </>
  );
};

export default Feedbacks;
