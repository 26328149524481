
import './App.css';

import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom'

import { CookiesProvider } from 'react-cookie';
import { useCookies } from 'react-cookie';

import {useRoutes} from './routes'

function App() {
 const routes = useRoutes();
  // if (get('lang'))
  const [cookies, setCookie] = useCookies(['lang']);
    if (cookies.lang!=='md' && cookies.lang!=='ru' ){
        setCookie('lang','md',{ path: '/' }) ;
        window.location.reload();
    } ;
    window.scrollTo(0, 0)
  return (
    <>
    <CookiesProvider>
    <Router>
          {routes}
    </Router>
    </CookiesProvider>
    </>
  );
}

export default App;
