import React from 'react'

const SidebarSocial = () => {
    return (
        <div className="social-sidebar-btns">
                        <a  target="_blank" 
                            href="https://www.instagram.com/envy.beautysalon/" 
                            title="ENVY in Instagram"
                            rel="noreferrer"
                            className="social-sidebar">
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a  target="_blank" 
                            href="viber://contact?number=%2B+37378552233" 
                            title="ENVY in Viber"
                            rel="noreferrer"
                            className="social-sidebar">
                            <i className="fab fa-viber"></i>
                        </a>
                        <a  target="_blank" 
                            href="https://www.facebook.com/ENVY-Beauty-Salon-107144934761236/" 
                            title="ENVY in Instagram"
                            rel="noreferrer"
                            className="social-sidebar">
                            <i className="fab fa-facebook"></i>
                        </a>
                        
                    </div>
    )
}

export default SidebarSocial
