import React from 'react'

const Socials = () => {
    return (
        <div className="social-btns">
                        <a  target="_blank" 
                            href="https://www.instagram.com/envy.beautysalon/" 
                            title="ENVY in Instagram"
                            rel="noreferrer"
                            className="social">
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a  target="_blank" 
                            href="viber://chat?number=%2B37378552233" 
                            title="ENVY in Viber"
                            rel="noreferrer"
                            className="social">
                            <i className="fab fa-viber"></i>
                        </a>
                        <a  target="_blank" 
                            href="https://www.facebook.com/ENVY-Beauty-Salon-107144934761236/" 
                            title="ENVY in Facabook.com"
                            rel="noreferrer"
                            className="social">
                            <i className="fab fa-facebook"></i>
                        </a>
                        
                    </div>
    )
}

export default Socials
