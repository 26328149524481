// import { Container } from '@material-ui/core'
import React, { useState, useEffect } from "react";
import {
  Gallery,
  Images,
  Pictures,
  Models,
  ModImg,
} from "../components/WorkPage/workEllements";
import "../components/WorkPage/works.css";

import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";

export const OurWork = () => {
  const Load = () => {
    window.scrollTo(0, 0);
  };
  window.onload = Load;
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");

  const [images, setImage] = useState(["0.png"]);

  const server = process.env.REACT_APP_MY_PUBLIC;
  useEffect(() => {
    (async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const { data } = await axios.get(`${apiUrl}/work/last_all`);
        const dataArray = data.map((pic) => `${server}${pic.pictures.path}`);
        setImage(dataArray);
      } catch (error) {
        console.log("Возникла ошибка : ", error);
      }
    })();
  }, []);

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  const listItems = images.map((number) => (
    <Pictures onClick={() => getImg(number)} className="picture">
      <div>
        <Images src={number}></Images>
      </div>
    </Pictures>
  ));

  return (
    <>
      <Models className={model ? "open" : ""}>
        <ModImg src={tempImgSrc} />
        <CloseIcon
          onClick={() => {
            setModel(false);
          }}
        />
      </Models>

      <Gallery>{listItems}</Gallery>
    </>
  );
};
