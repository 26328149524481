import React,{useState} from 'react'

import {  makeStyles } from '@material-ui/core/styles';
import { useCookies } from 'react-cookie';
import { LoginForm, LoginTittle, Login, User } from '../components/Loghin/LoghinEllement';
import { Button, InputAdornment, TextField } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: '10px 10px 10px 0',
        width: '100%',

        color:'black',
        '& Mui-focused':{
          color:'black'
        },
        '& label': {
          color:'Black',
          '&.Mui-focused':{
            color:'black'
          }
        },
        '& fieldset':{
          borderColor:'grey',
          '&:hover , &:focus':{
            borderColor:"grey"
          }
         },
         
      '& textarea':{
        color:'black',
        '&:hover , &:focus':{
          borderColor:"grey"
        }
      },
        '&.MuiButton-outlined':{
          borderColor:'black',
        }
      
       
      },
      
    },
    
  }));



const Loghin = () => {
    
    const classes = useStyles();
    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(['permission']);

    const [values, setValues] = React.useState({
        login: '',
        password: '',
        sent:false,
      });
    const resetForm = () =>{
        setValues({
        login: '',
        password: '',
        }) }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    

    const check = (e) =>{
        e.preventDefault();
        let data= {
            login:values.login,
            password:values.password,
        };
        var obj= {};
        fetch('/api/login.php', {
            method : "POST" , 
            headers : {
              'Content-Type': 'application/json',
            },
            body : JSON.stringify({data})
          })
          .then(response =>response.text())
          .then(response =>{
            obj=JSON.parse(response);
            if(obj.ok){changec(obj.permission,obj.id_user)} else {setLogined(false)}
          })

    }

    const [logined,setLogined] = useState(false); 
    const changec = (perm,id)=>{
        setLogined(true)
        resetForm();
        setTimeout(() => {
          
          setCookie('permission',perm,{ path: '/' })
          setCookie('id_user',id,{ path: '/' })
          window.location.reload()
        }, 2500);
    }

    



    
    return (
        <Login>
            <LoginForm>
                <User className={logined? 'ok' : 'no'}><i class="fas fa-fingerprint"></i></User>
                <LoginTittle>{!logined ? 'Login page' : 'Login Complete'}</LoginTittle>
                <form noValidate  
                        className={classes.root}
                      autoComplete="off">
                        {logined ? "" :
                        <>
                    <TextField
                            required
                            id="login"
                            size="small"
                            label={'Login'}
                            multiline
                            // required
                            value={values.login}
                            onChange={handleChange('login')}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <i class="fas fa-user"></i>
                                </InputAdornment>
                              ),
                            }}
                            />   
                    <TextField
                            required
                            id="password"
                            size="small"
                            type="password"
                            autoComplete="current-password"
                            label={'Password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <i class="fas fa-key"></i>
                                </InputAdornment>
                              ),
                            }}
                            />   
                            
                              <Button type="submit"                  
                              onClick={check}
                              >Enter</Button> </>}
                            
                                           
                 </form>
            </LoginForm>
        </Login>
    )
}

export default Loghin
