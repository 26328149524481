import React from 'react'
import { Puncts, PunctsAns, VacancyContainer, VacancyItem, VacancyItemHead, VCall ,Text ,TextAd} from '../components/Vacancy/VacancyEllements'

import { useCookies } from 'react-cookie';

export const Vacancy = () => {
    
    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(['lang']);
    window.scrollTo(0, 0)
    return (

        <>
            <VacancyContainer>
                <VacancyItem>
                    <VacancyItemHead>
                        {cookies.lang!=='md' ? 'Стилист' : 'Stilist'}
                    </VacancyItemHead>
                    <Puncts><i class="fas fa-city"></i> 
                        {cookies.lang!=='md' ? ' Город' : ' Oraș'} : 
                        <PunctsAns>{cookies.lang!=='md' ? '  Кишинев' : ' Chișinau'}</PunctsAns>
                    </Puncts>
                    <Puncts><i class="fas fa-list-ol"></i>
                        {cookies.lang!=='md' ? ' Опыт работы' : ' Experianta la munca'} : <PunctsAns>
                        {cookies.lang!=='md' ? ' Стаж от 2х лет' : ' De la 2 ani'}</PunctsAns>
                    </Puncts>
                    <Puncts><i class="fas fa-calendar-alt"></i> 
                        {cookies.lang!=='md' ? ' График работы' : ' Grafic de munca'} : <PunctsAns>
                        {cookies.lang!=='md' ? ' По сменам' : ' Munca in schimburi'}</PunctsAns>
                    </Puncts>
                    <Puncts><i class="fas fa-book-open"></i>
                        {cookies.lang!=='md' ? ' Образование' : ' Studii'} : <PunctsAns>
                        {cookies.lang!=='md' ? ' Любое' : '  Orice'}</PunctsAns>
                    </Puncts>
                    <Puncts><i class="fas fa-wallet"></i>
                    {cookies.lang!=='md' ? ' Зарплата' : ' Salariu'} : <PunctsAns>
                    {cookies.lang!=='md' ? ' Проценты' : ' Procente'}</PunctsAns></Puncts>

                    <Text>{cookies.lang!=='md' ? 'ENVY Beauty Salon , приглашает на работу Стилистов-профессионалов .  ' : ' ENVY Beauty Salon , invită la lucru Stilisti profesioniști . '}</Text>
                    <TextAd> Oferim:</TextAd>
                    <TextAd>- șansa de a activa într-un domeniu în plină ascensiune și dezvoltare;</TextAd>
                    <TextAd>- posibilitatea de a lucra alături de o echipă unită de profesioniști;</TextAd>
                    <TextAd>- angajare oficială în câmpul muncii;</TextAd>
                    <TextAd>- un loc de muncă confortabil dotat cu tot echipamentul necesar.</TextAd>
                    <TextAd>- salariu avantajos.</TextAd>
                    <VCall href="tel:+37378552233">{cookies.lang!=='md' ? ' Подробности' : ' detalii'}</VCall>
                </VacancyItem>
                <VacancyItem>
                    <VacancyItemHead>
                        {cookies.lang!=='md' ? 'Мастер - Бровист' : ' Maestrul - Sprâncenelor'}
                    </VacancyItemHead>
                    <Puncts><i class="fas fa-city"></i> 
                        {cookies.lang!=='md' ? ' Город' : ' Oraș'} : 
                        <PunctsAns>{cookies.lang!=='md' ? '  Кишинев' : ' Chișinau'}</PunctsAns>
                    </Puncts>
                    <Puncts><i class="fas fa-list-ol"></i>
                        {cookies.lang!=='md' ? ' Опыт работы' : ' Experianta la munca'} : <PunctsAns>
                        {cookies.lang!=='md' ? ' Стаж от 1 года' : ' De la 1 an'}</PunctsAns>
                    </Puncts>
                    <Puncts><i class="fas fa-calendar-alt"></i> 
                        {cookies.lang!=='md' ? ' График работы' : ' Grafic de munca'} : <PunctsAns>
                        {cookies.lang!=='md' ? ' По сменам' : ' Munca in schimburi'}</PunctsAns>
                    </Puncts>
                    <Puncts><i class="fas fa-book-open"></i>
                        {cookies.lang!=='md' ? ' Образование' : ' Studii'} : <PunctsAns>
                        {cookies.lang!=='md' ? ' Любое' : '  Orice'}</PunctsAns>
                    </Puncts>
                    <Puncts><i class="fas fa-wallet"></i>
                    {cookies.lang!=='md' ? ' Зарплата' : ' Salariu'} : <PunctsAns>
                    {cookies.lang!=='md' ? ' Проценты' : ' Procente'}</PunctsAns></Puncts>

                    <Text>{cookies.lang!=='md' ? 'ENVY Beauty Salon , приглашает на работу Мастеров Бровистов .  ' : ' ENVY Beauty Salon , invită la lucru Maestrul sprâncenelor . '}</Text>
                    <TextAd> Oferim:</TextAd>
                    <TextAd>- șansa de a activa într-un domeniu în plină ascensiune și dezvoltare;</TextAd>
                    <TextAd>- posibilitatea de a lucra alături de o echipă unită de profesioniști;</TextAd>
                    <TextAd>- angajare oficială în câmpul muncii;</TextAd>
                    <TextAd>- un loc de muncă confortabil dotat cu tot echipamentul necesar.</TextAd>
                    <TextAd>- salariu avantajos.</TextAd>
                    <VCall href="tel:+37378552233">{cookies.lang!=='md' ? ' Подробности' : ' detalii'}</VCall>
                </VacancyItem>
            </VacancyContainer>
        </>
    )
}

