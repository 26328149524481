import React from 'react'

import {PriceContainer ,  PricePage} from './PriceListEllements'
import ListService from './ListService';
import ListMaterials from './ListMaterials';



const PriceList = () => {
    
    return (
        <PricePage>
        
            <PriceContainer>
               
                <ListService/>

                <ListMaterials />



            </PriceContainer>
      
        </PricePage>
    )
}

export default PriceList
