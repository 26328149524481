import styled from 'styled-components'
export const Login = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height:100vh;
    background: #bbb;
`

export const LoginForm = styled.div`
    width:450px;
    height: auto;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow:5px 5px 10px 5px #9f9f9f;
`
export const User =styled.div`
    font-weight: 600;
    font-size: 60px;
    color:#474747;
    transition: 1s ease;
`

export const LoginTittle = styled.div`
    font-size: 24px;
    font-weight: 600;
`